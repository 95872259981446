// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: .5s all;
}

.modal.active {
  z-index: 100;
  opacity: 1;
  pointer-events: all;
}

.modal__content {
  font-size: 18px;
  padding: 20px;
  border-radius: 12px;
  background-color: black;
  transform: scale(0.5);
  transition: .4s all;
  
}

.modal__content.active {
  transform: scale(1);
}`, "",{"version":3,"sources":["webpack://./src/components/Moddal/modal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,oCAAoC;EACpC,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;;AAErB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".modal {\r\n  height: 100vh;\r\n  width: 100vw;\r\n  background-color: rgba(0, 0, 0, 0.4);\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  opacity: 0;\r\n  pointer-events: none;\r\n  transition: .5s all;\r\n}\r\n\r\n.modal.active {\r\n  z-index: 100;\r\n  opacity: 1;\r\n  pointer-events: all;\r\n}\r\n\r\n.modal__content {\r\n  font-size: 18px;\r\n  padding: 20px;\r\n  border-radius: 12px;\r\n  background-color: black;\r\n  transform: scale(0.5);\r\n  transition: .4s all;\r\n  \r\n}\r\n\r\n.modal__content.active {\r\n  transform: scale(1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
