import React from 'react'
import './footer.css'
import Logo from './../../img/logo.png'
import { Link } from 'react-router-dom'

function Footer() {
	return (
		<footer>
			<div className='container'>
				<div className='footer__wrapper'>
					<div className='footer__block-title'>
						<img className='footer__logo' src={Logo} alt='' />
						<h6 className='footer__title'>f-worlds</h6>
					</div>
					<div className='social-icons'>
						<Link
							className='link'
							to='https://discord.gg/YjamhjSAmn'
							target='_blank'
						>
							<i className='bx bxl-discord-alt'></i>
						</Link>
						<Link to='https://t.me/treska_fish' target='_blank'>
							<i class='bx bxl-telegram'></i>{' '}
						</Link>
						<Link to='https://www.youtube.com/@TreskaPro' target='_blank'>
							<i class='bx bxl-youtube'></i>{' '}
						</Link>
					</div>
				</div>

				<div className='footer__line'></div>

				<p className='scroll-scale'>&copy;2024</p>
			</div>
		</footer>
	)
}

export default Footer
