import React from 'react'
import './../styles/info.css'

import foks from '../img/users/foks.jpg'
import KISELEK from './../img/users/kiselek.gif'
import FOW from './../img/users/fow.png'
import nyatex from './../img/users/nyatex.jpg'
import myka from './../img/users/myka.jpg'
import sabday from './../img/users/sabday.jpg'
import angel from './../img/users/angel.jpg'
import kivit from './../img/users/kivit.jpg'
import santi from './../img/users/santy.jpg'
import regretttt from './../img/users/regretttt.jpg'
import TimShturm from './../img/users/TimShturm.jpg'

import draginIMG from './../img/infoPage/dragon.png'
import oneSeasonIMG from './../img/infoPage/oneSeason.png'

const InfoPage = () => {
	return (
		<>
			<section className='main-about'>
				<div className='container'>
					<div className='main-about__intro'>
						<h1 className='main-about__title'>
							О <span className='about__title-colored'>проекте</span>
						</h1>

						<div className='main-about__block'>
							<div className='main-about__block-text'>
								<p>
									<span className='about__title-colored'>FishWorlds </span> -
									это история о двух уникальных серверах, FishCraft и F-Worlds,
									которые объединились в сентябре 2024 года, чтобы создать
									совершенно новый масштабный проект. Теперь FishWorlds — это
									невероятный Minecraft-сервер, который уже успел стать самым
									обсуждаемым и популярным среди игроков. Здесь собрано лучшее
									комьюнити, каждый участник которого приносит что-то своё, от
									интересных идей до весёлых шуток, а также команда админов,
									способная не только решать любые вопросы, но и поддерживать
									каждый проект, делая миры FishWorlds ярче и увлекательнее.
									FishWorlds — это место, где креативность встречается с
									дружбой, где можно провести время с единомышленниками в ночных
									посиделках, обсуждая новые идеи, участвуя в масштабных
									челленджах или строя удивительные сооружения. Здесь царит
									особая сигма атмосфера, которая побуждает каждого игрока идти
									вперёд, пробовать что-то новое, исследовать миры и придумывать
									свои уникальные приключения. На этом сервере каждый находит
									свою команду, вместе с которой можно реализовать самые смелые
									идеи и наслаждаться компанией таких же увлечённых игроков.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='about-users'>
				<div className='container'>
					<div className='main-about__intro-one'>
						<h1 className='main-about__title-one'>
							Наш <span className='about__title-colored'>персонал</span>
						</h1>
					</div>

					<h1 className='user__spacer'>Лицо Проекта</h1>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={nyatex} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>Treska0_0</span>
									<div className='user__roles user__roles-founder'>
										<h3 className='user__roles__names-txt'>Основатель</h3>
									</div>
								</div>
								<div className='user__line role-founder'></div>
								<div className='user__text-content'>
									<span>{'Сотрудничество, Основатель'}</span>
								</div>
							</div>
						</div>
					</div>

					<h1 className='user__spacer'>Помощь на сервере</h1>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={santi} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>Santy</span>
									<div className='user__roles user__roles-moderator'>
										<h3 className='user__roles__names-txt'>Ст. Хелпер</h3>
									</div>
								</div>
								<div className='user__line role-helper'></div>
								<div className='user__text-content'>
									<span>Ст. Хелпер</span>
								</div>
							</div>
						</div>
					</div>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={angel} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>Angel</span>
									<div className='user__roles user__roles-moderator'>
										<h3 className='user__roles__names-txt'>Хелпер</h3>
									</div>
								</div>
								<div className='user__line role-helper'></div>
								<div className='user__text-content'>
									<span>{'Хелпер'}</span>
								</div>
							</div>
						</div>
					</div>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={kivit} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>Kivit</span>
									<div className='user__roles user__roles-moderator'>
										<h3 className='user__roles__names-txt'>Хелпер</h3>
									</div>
								</div>
								<div className='user__line role-helper'></div>
								<div className='user__text-content'>
									<span>{'Кр. Хелпер'}</span>
								</div>
							</div>
						</div>
					</div>

					<h1 className='user__spacer'>Дизайн проекта</h1>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={FOW} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>Иоанн(Fow)</span>
									<div className='user__roles user__roles-flow'>
										<h3 className='user__roles__names-txt'>Художник</h3>
									</div>
								</div>
								<div className='user__line role-design'></div>
								<div className='user__text-content'>
									<span>Художник</span>
								</div>
							</div>
						</div>
					</div>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={sabday} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>SabDay</span>
									<div className='user__roles user__roles-smm'>
										<h3 className='user__roles__names-txt'>СММ</h3>
									</div>
								</div>
								<div className='user__line role-design'></div>
								<div className='user__text-content'>
									<span>СММ</span>
								</div>
							</div>
						</div>
					</div>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={KISELEK} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>_Kiselek_</span>
									<div className='user__roles user__roles-founder'>
										<h3 className='user__roles__names-txt'>Основатель</h3>
									</div>
								</div>
								<div className='user__line role-design'></div>
								<div className='user__text-content'>
									<span>ТекстурПак, Основатель</span>
								</div>
							</div>
						</div>
					</div>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={TimShturm} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>TimShturm</span>
									<div className='user__roles user__roles-design'>
										<h3 className='user__roles__names-txt'>Дизайнер</h3>
									</div>
								</div>
								<div className='user__line role-design'></div>
								<div className='user__text-content'>
									<span>Веб-Дизайнер</span>
								</div>
							</div>
						</div>
					</div>

					<h1 className='user__spacer'>Техническая часть</h1>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={foks} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>Foks_f</span>
									<div className='user__roles user__roles-founder'>
										<h3 className='user__roles__names-txt'>Основатель</h3>
									</div>
								</div>
								<div className='user__line role-tech'></div>
								<div className='user__text-content'>
									<span>Let me die.</span>
								</div>
							</div>
						</div>
					</div>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={myka} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>Мука</span>
									<div className='user__roles user__roles-java'>
										<h3 className='user__roles__names-txt'>Тех. админ</h3>
									</div>
								</div>
								<div className='user__line role-tech'></div>
								<div className='user__text-content'>
									<span>Плагины</span>
								</div>
							</div>
						</div>
					</div>

					<div className='user__group'>
						<div className='user__content'>
							<img className='user__avatar' src={regretttt} alt='' />
							<div className='user__wrapper'>
								<div className='user__roles-name'>
									<span className='user__name'>regretttt</span>
									<div className='user__roles user__roles-frontend'>
										<h3 className='user__roles__names-txt'>Фронтенд</h3>
									</div>
								</div>
								<div className='user__line role-tech'></div>
								<div className='user__text-content'>
									<span>Пожелания по сайту</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { InfoPage }
