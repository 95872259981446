import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import axios from 'axios'

import api from '../utils/api.js'
import './../styles/success.css'

export const Success = () => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		mode: 'onSubmit',
	})

	const onSubmit = data => {
		// console.log(JSON.stringify(errors))
		// console.log('subbb')
		if (Object.keys(errors).length > 0) {
			toast.error('Поля заполнены не корректно')
			reset()
		} else {
			// Check for empty fields

			const emptyFields = []

			for (const key in data) {
				if (data[key] === '') {
					emptyFields.push(key)
				}
			}

			if (emptyFields.length > 0) {
				toast.error('Поля не могут быть пустыми')

				// reset()
			} else {
				successSubmit(data)
			}
		}
	}

	const successSubmit = data => {
		const { username, age, whois, plans, experience, telegram } = data
		const postData = {
			username,
			age,
			whois,
			plans,
			experience,
			telegram,
		}
		try {
			axios
				.post('http://95.216.100.112:8080/api/succes/', postData)
				.then(res => {
					if (data) {
						toast.success('Заявка успешно отправлена! Желаем удачи :)')
						reset()
					}
				})
		} catch (err) {
			toast.error('Ошибка отправки данных')
		}
	}

	const onError = errors => {
		Object.values(errors).forEach(error => {
			toast.error(error.message)
		})
	}

	return (
		<section>
			<div className='container'>
				<form onSubmit={handleSubmit(onSubmit, onError)}>
					<div className='input__wrapper'>
						<div className='input__wrapper_wrap'>
							<h1>
								Отправить <span>заявку</span> на сервер.
							</h1>
							<div className='input__block'>
								<input
									{...register('username', {
										// required: true,
									})}
									type='text'
									placeholder='Ваш ник в Minecraft'
									maxLength='17'
								/>
							</div>
							<div className='input__block'>
								<input
									{...register('age', {
										required: true,
										min: {
											value: 11,
											message: 'А мама разрешила? Вот 11 будет, поговорим',
										},
										max: {
											value: 99,
											message: 'а если честно?)',
										},
									})}
									maxLength='2'
									type='number'
									placeholder='Ваш возраст'
								/>
							</div>
							<div className='input__block'>
								<input
									{...register('whois', {
										// required: true,
									})}
									type='text'
									placeholder='Откуда вы узнали о сервере?'
									maxLength='15'
								/>
							</div>
							<div className='input__block'>
								<textarea
									{...register('plans', {
										// required: true,
									})}
									type='text'
									placeholder='Чем вы хотите заниматься на сервере'
									maxLength='100'
								/>
							</div>
							<div className='input__block'>
								<input
									{...register('experience', {
										// required: true,
									})}
									type='text'
									placeholder='Играли ли на приватных серверах?'
									maxLength='30'
								/>
							</div>
							<div className='input__block'>
								<input
									{...register('telegram', {
										// required: true,
									})}
									type='text'
									placeholder='Ваш тэг дискорд'
									maxLength='30'
								/>
							</div>

							<div className='input__block_button'>
								<input type='submit' value='Отправить заявку' />
							</div>
						</div>
					</div>
				</form>
			</div>
		</section>
	)
}
