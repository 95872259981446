// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__h\\+9Ux {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin-top: 30px;
	padding: 20px;
	width: 400px;
	height: auto;
	background-color: #242526;
	border-radius: 20px;
}

.style_main__line__VMY\\+F {
	display: block;
	width: 100%;
	background-color: #8b490a;
	height: 4px;
	border-radius: 4px;
	margin: 5px 0 5px 0;
}

.style_main__area__F7S36 {
	display: flex;
	justify-content: space-between;
	font-size: 22px;
	font-weight: 500;
}

.style_main__area__F7S36 h1 {
	padding-right: 30px;
}

.style_button__5zMsj {
	margin-top: 25px;
	padding: 15px;
	width: 100%;
	color: #e0e0e0;
	background-color: #388e3c;
	margin-right: 20px;
	font-size: 30px;

	border-radius: 20px;
	transition: all 0.2s;
}

/* .main__info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
} */

.style_button2__pFANW {
	margin-top: 25px;
	padding: 15px;
	width: 100%;
	color: #e0e0e0;
	background-color: #d32f2f;
	font-size: 30px;

	border-radius: 20px;
	transition: all 0.2s;
}

.style_button__5zMsj:hover {
	background-color: #47b44c;
}

.style_button2__pFANW:hover {
	background-color: #f73939;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserItem/style.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,8BAA8B;;CAE9B,gBAAgB;CAChB,aAAa;CACb,YAAY;CACZ,YAAY;CACZ,yBAAyB;CACzB,mBAAmB;AACpB;;AAEA;CACC,cAAc;CACd,WAAW;CACX,yBAAyB;CACzB,WAAW;CACX,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,aAAa;CACb,WAAW;CACX,cAAc;CACd,yBAAyB;CACzB,kBAAkB;CAClB,eAAe;;CAEf,mBAAmB;CACnB,oBAAoB;AACrB;;AAEA;;;;GAIG;;AAEH;CACC,gBAAgB;CAChB,aAAa;CACb,WAAW;CACX,cAAc;CACd,yBAAyB;CACzB,eAAe;;CAEf,mBAAmB;CACnB,oBAAoB;AACrB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".main {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tjustify-content: space-between;\r\n\r\n\tmargin-top: 30px;\r\n\tpadding: 20px;\r\n\twidth: 400px;\r\n\theight: auto;\r\n\tbackground-color: #242526;\r\n\tborder-radius: 20px;\r\n}\r\n\r\n.main__line {\r\n\tdisplay: block;\r\n\twidth: 100%;\r\n\tbackground-color: #8b490a;\r\n\theight: 4px;\r\n\tborder-radius: 4px;\r\n\tmargin: 5px 0 5px 0;\r\n}\r\n\r\n.main__area {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\tfont-size: 22px;\r\n\tfont-weight: 500;\r\n}\r\n\r\n.main__area h1 {\r\n\tpadding-right: 30px;\r\n}\r\n\r\n.button {\r\n\tmargin-top: 25px;\r\n\tpadding: 15px;\r\n\twidth: 100%;\r\n\tcolor: #e0e0e0;\r\n\tbackground-color: #388e3c;\r\n\tmargin-right: 20px;\r\n\tfont-size: 30px;\r\n\r\n\tborder-radius: 20px;\r\n\ttransition: all 0.2s;\r\n}\r\n\r\n/* .main__info {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tjustify-content: space-between;\r\n} */\r\n\r\n.button2 {\r\n\tmargin-top: 25px;\r\n\tpadding: 15px;\r\n\twidth: 100%;\r\n\tcolor: #e0e0e0;\r\n\tbackground-color: #d32f2f;\r\n\tfont-size: 30px;\r\n\r\n\tborder-radius: 20px;\r\n\ttransition: all 0.2s;\r\n}\r\n\r\n.button:hover {\r\n\tbackground-color: #47b44c;\r\n}\r\n\r\n.button2:hover {\r\n\tbackground-color: #f73939;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__h+9Ux`,
	"main__line": `style_main__line__VMY+F`,
	"main__area": `style_main__area__F7S36`,
	"button": `style_button__5zMsj`,
	"button2": `style_button2__pFANW`
};
export default ___CSS_LOADER_EXPORT___;
