import './style.css'
import { NavLink } from 'react-router-dom'
import Logo from './../../img/logo.png'

function Header() {
	const activeLink = 'nav__link nav__link-active'
	const orangeLink = 'orange__link'

	const normalLink = 'nav__link'
	const normalLinkO = 'nav__link' + ' ' + orangeLink

	const isAuth = () => {
		const access_token = window.localStorage.getItem('access_token')

		if (access_token) {
			return true
		}

		return false
	}

	return (
		<header id='home'>
			<div className='container'>
				<div className='header__inner'>
					<div className='header__block'>
						<div className='header__logo'>
							<NavLink className='header__logo-wrapper' to='/'>
								<img src={Logo} alt='' className='header__img' />
								<h4 className='header__home nav__link'>Главная</h4>
							</NavLink>
						</div>
					</div>

					<nav className='nav'>
						{isAuth() ? (
							<NavLink
								className={({ isActive }) =>
									isActive ? activeLink : normalLink
								}
								to='/admin/dashboard'
							>
								прием заявок
							</NavLink>
						) : (
							<></>
						)}

						<NavLink
							className={({ isActive }) => (isActive ? activeLink : normalLink)}
							to='/info'
						>
							информация
						</NavLink>
						{/* <NavLink
							className={({ isActive }) => (isActive ? activeLink : normalLink)}
							to='/rules'
						>
							правила
						</NavLink>
						<NavLink
							className={({ isActive }) => (isActive ? activeLink : normalLink)}
							to='/donate'
						>
							донат
						</NavLink> */}
						{/* <NavLink
							className={({ isActive }) =>
								isActive ? activeLink : normalLinkO
							}
							to='/success'
						>
							заявки
						</NavLink> */}
					</nav>
				</div>
			</div>
		</header>
	)
}

export default Header
