// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.footer__wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding-top: 20px;
	padding-bottom: 20px;
}

.footer__line {
	width: 100%;
	padding-top: 4px;
	background: var(--hover-color);
	border-radius: 4px;
}

.footer__block-title {
	display: flex;
	align-items: center;
}

.footer__title {
	font-size: 16px;
	font-weight: 500;
	padding-left: 10px;
	text-transform: capitalize;
}

.footer__sub-title {
	margin-top: 3px;
	font-size: 14px;
	color: var(--text-color);
}

footer p h6 {
	color: #bdbdbd;
}

footer a {
	display: inline-flex;
	justify-content: center;
	color: var(--text-color);
	background: var(--hover-color);
	padding: 0.6rem;
	border-radius: 5px;
}

footer a i {
	font-size: 1.3rem;
	color: var(--secon-bg-color);
}

.footer__logo {
	width: 20px;
	border-radius: 10px;
}

.scroll-scale {
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,WAAW;CACX,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;;CAEnB,iBAAiB;CACjB,oBAAoB;AACrB;;AAEA;CACC,WAAW;CACX,gBAAgB;CAChB,8BAA8B;CAC9B,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,kBAAkB;CAClB,0BAA0B;AAC3B;;AAEA;CACC,eAAe;CACf,eAAe;CACf,wBAAwB;AACzB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,oBAAoB;CACpB,uBAAuB;CACvB,wBAAwB;CACxB,8BAA8B;CAC9B,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;CACjB,4BAA4B;AAC7B;;AAEA;CACC,WAAW;CACX,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,oBAAoB;AACrB","sourcesContent":["footer {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: space-between;\r\n}\r\n\r\n.footer__wrapper {\r\n\twidth: 100%;\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n\r\n\tpadding-top: 20px;\r\n\tpadding-bottom: 20px;\r\n}\r\n\r\n.footer__line {\r\n\twidth: 100%;\r\n\tpadding-top: 4px;\r\n\tbackground: var(--hover-color);\r\n\tborder-radius: 4px;\r\n}\r\n\r\n.footer__block-title {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n}\r\n\r\n.footer__title {\r\n\tfont-size: 16px;\r\n\tfont-weight: 500;\r\n\tpadding-left: 10px;\r\n\ttext-transform: capitalize;\r\n}\r\n\r\n.footer__sub-title {\r\n\tmargin-top: 3px;\r\n\tfont-size: 14px;\r\n\tcolor: var(--text-color);\r\n}\r\n\r\nfooter p h6 {\r\n\tcolor: #bdbdbd;\r\n}\r\n\r\nfooter a {\r\n\tdisplay: inline-flex;\r\n\tjustify-content: center;\r\n\tcolor: var(--text-color);\r\n\tbackground: var(--hover-color);\r\n\tpadding: 0.6rem;\r\n\tborder-radius: 5px;\r\n}\r\n\r\nfooter a i {\r\n\tfont-size: 1.3rem;\r\n\tcolor: var(--secon-bg-color);\r\n}\r\n\r\n.footer__logo {\r\n\twidth: 20px;\r\n\tborder-radius: 10px;\r\n}\r\n\r\n.scroll-scale {\r\n\ttext-align: center;\r\n\tpadding-top: 40px;\r\n\tpadding-bottom: 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
